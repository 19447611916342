/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font: 400 14px/20px Roboto, 'Helvetica Neue', sans-serif;
    font-family: Avenir, Nunito;
}

// temporary fix for expansion panels until Angular Material Issue is fixed,
// see https://github.com/angular/material2/issues/13870 https://github.com/angular/components/issues/11765
mat-accordion mat-expansion-panel {
    mat-expansion-panel-header {
        height: 40px; // height may be different for you
    }
    .mat-expansion-panel-content {
        height: 0;
    }
    &.mat-expanded {
        mat-expansion-panel-header {
            height: 64px; // height may be different for you
        }
        .mat-expansion-panel-content {
            height: auto;
        }
    }
}
